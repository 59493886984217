import React, { useContext, useEffect, useState } from "react";
import styles from "./Cart.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import { useSearchParams } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Button from "../../../components/Button";

const CartAssemblyTasks: React.FC = observer(() => {
  const {
    orderStore: { getAssemblyTask },
    yandexStore: { ApproveYandexShipment, getYandexStocks, yandexStocks },
    ozonStore: { ApproveOzonShipment, getOzonStocks, ozonStocks },
    wildberriesStore: { ApproveWildberriesShipment },
    userStore: { info },
  } = useContext(RootStoreContext);

  const [approveLoading, setApproveLoading] = useState<boolean>(false);

  const [yandexAssemblyTask, setYandexAssemblyTask] = useState<any>(null);
  const [ozonAssemblyTask, setOzonAssemblyTask] = useState<any>(null);
  const [wildberriesAssemblyTask, setWildberriesAssemblyTask] =
    useState<any>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const marketplace = searchParams.get("marketplace");
  const seller = searchParams.get("seller");

  useEffect(() => {
    if (seller) {
      getAssemblyTask(seller, 1).then((i) => {
        setOzonAssemblyTask(window.URL.createObjectURL(new Blob([i])));
      });
      getAssemblyTask(seller, 2).then((i) => {
        setYandexAssemblyTask(window.URL.createObjectURL(new Blob([i])));
      });
      getAssemblyTask(seller, 3).then((i) => {
        setWildberriesAssemblyTask(window.URL.createObjectURL(new Blob([i])));
      });
    }
  }, [getAssemblyTask, seller]);

  useEffect(() => {
    if (info) {
      getOzonStocks(info.id);
      getYandexStocks(info.id);
    }
  }, [info, getYandexStocks, getOzonStocks]);
  return (
    <>
      {seller ? (
        <DropdownButton
          title="Скачать сборочные задания"
          className={styles.downloadList}
        >
          {ozonAssemblyTask ? (
            <Dropdown.Item
              eventKey={1}
              onClick={() => {
                const a = document.createElement("a");
                a.href = ozonAssemblyTask;
                a.download = "ozonAssemblyTask.pdf";
                document.body.appendChild(a);
                a.click();
              }}
            >
              Ozon
            </Dropdown.Item>
          ) : (
            ""
          )}
          {yandexAssemblyTask ? (
            <Dropdown.Item
              eventKey={2}
              onClick={() => {
                const a = document.createElement("a");
                a.href = yandexAssemblyTask;
                a.download = "yandexMarketAssemblyTask.pdf";
                document.body.appendChild(a);
                a.click();
              }}
            >
              Yandex Market
            </Dropdown.Item>
          ) : (
            ""
          )}
          {wildberriesAssemblyTask ? (
            <Dropdown.Item
              eventKey={3}
              onClick={() => {
                const a = document.createElement("a");
                a.href = wildberriesAssemblyTask;
                a.download = "wildberriesAssemblyTask.pdf";
                document.body.appendChild(a);
                a.click();
              }}
            >
              Wildberries
            </Dropdown.Item>
          ) : (
            ""
          )}
        </DropdownButton>
      ) : (
        ""
      )}
      {seller && marketplace ? (
        <div
          className={
            approveLoading ? styles.approveButtonLoading : styles.approveButton
          }
        >
          <Button
            name="approve"
            onClick={() => {
              if (Number(marketplace) === 1 && ozonStocks) {
                setApproveLoading(true);
                ozonStocks.map(({ item1 }) => {
                  ApproveOzonShipment(seller, item1).then(() => {
                    setApproveLoading(false);
                  });
                });
              } else if (Number(marketplace) === 2 && yandexStocks) {
                setApproveLoading(true);
                yandexStocks.map(({ item1 }) => {
                  ApproveYandexShipment(seller, item1).then(() => {
                    setApproveLoading(false);
                  });
                });
              } else {
                setApproveLoading(true);
                ApproveWildberriesShipment(seller).then(() => {
                  setApproveLoading(false);
                });
              }
            }}
          >
            Подтвердить акт отгрузки
          </Button>
        </div>
      ) : (
        ""
      )}
    </>
  );
});
export default CartAssemblyTasks;
