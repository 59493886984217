import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./MenuSection.module.scss";
import { Container } from "react-bootstrap";
import Hamburger from "hamburger-react";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { LightLogo } from "../../../components/SvgIcons";

interface Props {
  contactRef: any;
  aboutRef: any;
  advantageRef: any;
  reilablyRef: any;
  sellerRef: any;
}

const MenuSection: React.FC<Props> = ({
  contactRef,
  aboutRef,
  advantageRef,
  reilablyRef,
  sellerRef,
}) => {
  const [sticky, setSticky] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setSticky(offset > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const topScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleScrollTo = (ref: any) => {
    window.scrollTo({
      top: ref.offsetTop - 120,
      left: 0,
      behavior: "smooth",
    });
  };
  console.log(location.pathname);
  const menu = [
    {
      title: "О нас",
      onclick: () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        location.pathname === "/suppliers"
          ? navigate("/")
          : handleScrollTo(aboutRef.current);
        setMenuOpen(false);
      },
    },
    {
      title: "Услуги",
      onclick: () => {},
    },
    {
      title: "Преимущества",
      onclick: () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        location.pathname === "/suppliers"
          ? navigate("/")
          : handleScrollTo(reilablyRef.current);
        setMenuOpen(false);
      },
    },
    {
      title: "Маркетплейсы",
      onclick: () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        location.pathname === "/suppliers"
          ? navigate("/")
          : handleScrollTo(advantageRef.current);
        setMenuOpen(false);
      },
    },
    {
      title: "Поставщикам",
      onclick: () => {
        navigate("/suppliers");
      },
    },
    {
      title: "Условия",
      onclick: () => {},
    },
    {
      title: "Контакты",
      onclick: () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        location.pathname === "/suppliers"
          ? navigate("/")
          : handleScrollTo(contactRef.current);
        setMenuOpen(false);
      },
    },
  ];

  return (
    <div className={styles.Menu}>
      <Container className={styles.MenuHeader}>
        <div className={styles.MobileMenu}>
          <Link to="/" className={styles.Logo}>
            <LightLogo />
          </Link>
          <div className={styles.ToggleMenu}>
            <Hamburger
              toggled={menuOpen}
              toggle={handleToggleMenu}
              color="#fff"
              size={42}
            />
          </div>
        </div>
        {menuOpen && (
          <ul className={styles.MenuMobileUl}>
            {menu.map(({ title, onclick }, index) => {
              return (
                <li key={index} onClick={onclick}>
                  {title}
                </li>
              );
            })}
          </ul>
        )}

        <ul className={styles.MenuUl}>
          {menu.map(({ title, onclick }, index) => {
            return (
              <li key={index} onClick={onclick}>
                {title}
              </li>
            );
          })}
        </ul>
      </Container>
      <IoIosArrowDropupCircle
        color="#f49100"
        size={72}
        className={sticky ? styles.scrollTop : styles.scrollTopHidden}
        onClick={() => topScroll()}
      />
    </div>
  );
};

export default MenuSection;
