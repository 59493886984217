import React, { useContext, useEffect, useState } from "react";
import styles from "./Cart.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import { useSearchParams } from "react-router-dom";
import OzonLogo from "assets/icons/ozon.png";
import WildberriesLogo from "assets/icons/wildberries.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import YandexLogo from "../../../assets/icons/yandex.png";

const CartShipmentActs: React.FC = observer(() => {
  const {
    orderStore: { getOrdersCount },
    wildberriesStore: { getWildberriesShipmentAct },
    yandexStore: { getYandexShipmentAct },
    ozonStore: { getOzonShipmentAct },
  } = useContext(RootStoreContext);

  const [yandexShipmentAct, setYandexShipmentAct] = useState<any>(null);
  const [ozonShipmentAct, setOzonShipmentAct] = useState<any>(null);
  const [wildberriesShipmentAct, setWildberriesShipmentAct] = useState<any>("");

  const [yandexOrdersCount, setYandexOrdersCount] = useState<string>("");
  const [ozonOrdersCount, setOzonOrdersCount] = useState<string>("");
  const [wildberriesOrdersCount, setWildberriesOrdersCount] =
    useState<string>("");

  const [searchParams, setSearchParams] = useSearchParams();

  const seller = searchParams.get("seller");

  useEffect(() => {
    setYandexShipmentAct(null);
    setOzonShipmentAct(null);
    setWildberriesShipmentAct(null);
    if (seller) {
      setOzonOrdersCount("");
      setWildberriesOrdersCount("");
      setYandexOrdersCount("");
      getYandexShipmentAct(seller).then((i) => {
        if (i) {
          setYandexShipmentAct(window.URL.createObjectURL(new Blob([i])));
        }
      });
      getOzonShipmentAct(seller).then((i) => {
        if (i) {
          setOzonShipmentAct(window.URL.createObjectURL(new Blob([i])));
        }
      });
      getWildberriesShipmentAct(seller).then((i) => {
        if (i) {
          setWildberriesShipmentAct(window.URL.createObjectURL(new Blob([i])));
        }
      });
      getOrdersCount(seller, 1).then((i) => {
        setOzonOrdersCount(i);
      });
      getOrdersCount(seller, 2).then((i) => {
        setYandexOrdersCount(i);
      });
      getOrdersCount(seller, 3).then((i) => {
        setWildberriesOrdersCount(i);
      });
    }
  }, [
    getWildberriesShipmentAct,
    getYandexShipmentAct,
    getOzonShipmentAct,
    getOrdersCount,
    seller,
  ]);
  return (
    <>
      {seller ? (
        yandexShipmentAct ? (
          <div className={styles.marketPlacesLogo}>
            <OverlayTrigger overlay={<Tooltip>Скачать акт отгрузки</Tooltip>}>
              <div>
                <a href={yandexShipmentAct} download="yandexShipmentAct.pdf">
                  <img src={YandexLogo} alt="yandex market logo" />
                </a>
                <span>({yandexOrdersCount})</span>
              </div>
            </OverlayTrigger>
          </div>
        ) : (
          <div className={styles.marketPlacesLogo}>
            <OverlayTrigger
              overlay={<Tooltip>акт отгрузки отсутствует</Tooltip>}
            >
              <div>
                <img src={YandexLogo} alt="yandex market logo" />
                <span>({yandexOrdersCount})</span>
              </div>
            </OverlayTrigger>
          </div>
        )
      ) : (
        ""
      )}
      {seller ? (
        ozonShipmentAct ? (
          <div className={styles.marketPlacesLogo}>
            <OverlayTrigger overlay={<Tooltip>Скачать акт отгрузки</Tooltip>}>
              <div>
                <a href={ozonShipmentAct} download="ozonShipmentAct.pdf">
                  <img src={OzonLogo} alt="ozon market logo" />
                </a>
                <span>({ozonOrdersCount})</span>
              </div>
            </OverlayTrigger>
          </div>
        ) : (
          <div className={styles.marketPlacesLogo}>
            <OverlayTrigger
              overlay={<Tooltip>акт отгрузки отсутствует</Tooltip>}
            >
              <div>
                <img src={OzonLogo} alt="ozon market logo" />
                <span>({ozonOrdersCount})</span>
              </div>
            </OverlayTrigger>
          </div>
        )
      ) : (
        ""
      )}
      {seller ? (
        wildberriesShipmentAct ? (
          <div className={styles.marketPlacesLogo}>
            <OverlayTrigger overlay={<Tooltip>Скачать акт отгрузки</Tooltip>}>
              <div>
                <a
                  href={wildberriesShipmentAct}
                  download="wildberriesShipmentAct.pdf"
                >
                  <img src={WildberriesLogo} alt="wildberries market logo" />
                </a>
                <span>({wildberriesOrdersCount})</span>
              </div>
            </OverlayTrigger>
          </div>
        ) : (
          <div className={styles.marketPlacesLogo}>
            <OverlayTrigger
              overlay={<Tooltip>акт отгрузки отсутствует</Tooltip>}
            >
              <div>
                <img src={WildberriesLogo} alt="wildberries market logo" />
                <span>({wildberriesOrdersCount})</span>
              </div>
            </OverlayTrigger>
          </div>
        )
      ) : (
        ""
      )}
    </>
  );
});
export default CartShipmentActs;
