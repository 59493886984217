import { Table } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Invoices.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import Button from "components/Button";
import moment from "moment";

import Modal from "components/Modal";
import PayPopup from "./PayPopup";
import BeatLoader from "react-spinners/BeatLoader";

const Invoices: React.FC = observer(() => {
  const {
    orderStore: { getInvoices, invoices },
  } = useContext(RootStoreContext);

  const [loading, setLoading] = useState<boolean>(true);

  const [payItems, setPayItems] = useState<
    {
      price: number;
      invoiceNumber: number;
    }[]
  >([]);
  const [isPay, setIsPay] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getInvoices().then(() => {
      setLoading(false);
    });
  }, [getInvoices, setLoading]);

  const checkPayItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setPayItems([
        ...payItems,
        {
          price: Number(e.target.value.split(",")[0]),
          invoiceNumber: Number(e.target.value.split(",")[1]),
        },
      ]);
    } else {
      setPayItems([
        ...payItems.filter(
          (i) => i.invoiceNumber !== Number(e.target.value.split(",")[1])
        ),
      ]);
    }
  };

  return (
    <div className={styles.root}>
      <p className={styles.title}>Накладные</p>
      <div className={styles.wrapper}>
        {loading ? (
          <div style={{ width: "fit-content", margin: "auto" }}>
            <BeatLoader color={"#f49100"} loading={loading} size={50} />
          </div>
        ) : (
          <Table striped hover>
            <thead>
              <tr>
                <th>
                  <div className={styles.theadTh}>Маркетплейс</div>
                </th>
                <th>
                  <div className={styles.theadTh}>Продавец</div>
                </th>
                <th>
                  <Button
                    className={styles.payTbButton}
                    disabled={payItems.length ? false : true}
                    onClick={() => setIsPay(true)}
                  >
                    {`Оплатить (${payItems.length})`}
                  </Button>
                </th>
                <th>Дата отгрузки</th>
              </tr>
            </thead>
            <tbody>
              {invoices &&
                invoices.map((item, index) => (
                  <tr key={index}>
                    <td>{item.marketPlaceName}</td>
                    <td>{item.seller}</td>
                    <td className={styles.payCheckbox}>
                      <input
                        type="checkbox"
                        value={[
                          item.price.toString(),
                          item.invoiceNumber.toString(),
                        ]}
                        onChange={(e) => checkPayItem(e)}
                      />
                    </td>
                    <td>{moment(item.createDate).format("DD.MM.YYYY")}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}

        {isPay && (
          <Modal
            className={styles.cartModal}
            children={<PayPopup payItems={payItems} />}
            isOpen={isPay}
            onClose={() => {
              setIsPay(false);
            }}
          />
        )}
      </div>
    </div>
  );
});
export default Invoices;
