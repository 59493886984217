import { Col, Row, Table } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import styles from "./ProfileSettings.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import UploadDocuments from "./UploadDocuments";
import { BankInfo } from "../../../stores/UserStore";
import CustomInput from "../../../components/CustomInput";
import Button from "../../../components/Button";
import ToggleSwitch from "../../../components/ToggleSwitch";
import Logistics from "./Logistics";
import CategoriesTree from "./CategoriesTree";
import AgreementUsage from "./Agreement_Usage";

const ProfileSettings: React.FC = observer(() => {
  const {
    userStore: {
      info,
      userInfo,
      getBankInfo,
      changeBankInfo,
      setInfo,
      getTermsOfUsage,
    },
    loginStore: { updateEnableTwoFactor },
    emailStore: { getUserSubscription, updateUserSubscription },
  } = useContext(RootStoreContext);

  const [bankInfo, setBankInfo] = useState<BankInfo | null>();
  const [changeLoad, setChangeLoad] = useState<boolean>(false);

  const [loadTwoFactor, setLoadTwoFactor] = useState<boolean>(false);
  const [userSubscribe, setUserSubscribe] = useState<boolean>(false);
  const [loadUserSubscribe, setLoadUserSubscribe] = useState<boolean>(false);
  useEffect(() => {
    getTermsOfUsage();
    if (info?.id) {
      getBankInfo(info.id).then((bankInfo) => {
        setBankInfo(bankInfo);
      });
      getUserSubscription(info.id).then((i) => {
        setUserSubscribe(i);
      });
    }
  }, [getBankInfo, info?.id, getTermsOfUsage, getUserSubscription]);

  return (
    <div className={styles.root}>
      <p className={styles.title}>Настройки профиля</p>
      <div className={styles.wrapper}>
        <Row>
          <Col
            xl={info?.roles.includes("Seller") ? 4 : 7}
            lg={6}
            md={12}
            sm={12}
            xs={12}
          >
            <Table striped hover responsive>
              <tbody>
                <tr>
                  <td colSpan={2} style={{ textAlign: "center" }}>
                    <b>Личные данные</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Имя</b>
                  </td>
                  <td>{userInfo?.firstName}</td>
                </tr>
                <tr>
                  <td>
                    <b>Фамилия</b>
                  </td>
                  <td>{userInfo?.lastName ? userInfo?.lastName : "N/A"}</td>
                </tr>
                <tr>
                  <td>
                    <b>Почта</b>
                  </td>
                  <td>{userInfo?.email}</td>
                </tr>
                <tr>
                  <td>
                    <b>Номер телефона</b>
                  </td>
                  <td>
                    {userInfo?.phoneNumber ? userInfo?.phoneNumber : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Адрес регистрации</b>
                  </td>
                  <td>
                    {userInfo?.phoneNumber ? userInfo?.phoneNumber : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Роль</b>
                  </td>
                  <td>
                    {info?.roles.includes("WarehouseOwner")
                      ? "Владелец склада"
                      : "Продавец"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>ИНН</b>
                  </td>
                  <td>{userInfo?.tin ? userInfo?.tin : "N/A"}</td>
                </tr>
                {info ? (
                  <tr>
                    <td>
                      <b>Двухфакторная аутентификация</b>
                    </td>
                    <td>
                      <ToggleSwitch
                        checked={info.twoFactorEnabled}
                        cursor={loadTwoFactor ? "progress" : "pointer"}
                        onChange={() => {
                          setLoadTwoFactor(true);
                          updateEnableTwoFactor(
                            info?.id,
                            !info.twoFactorEnabled
                          ).then((i) => {
                            if (i) {
                              setInfo({
                                ...info,
                                twoFactorEnabled: !info?.twoFactorEnabled,
                              });
                            }
                            setLoadTwoFactor(false);
                          });
                        }}
                        name="import"
                        leftText="Отключить"
                        rightText="Подключить"
                        icons={false}
                      />
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {info ? (
                  <tr>
                    <td>
                      <b>Подписка на рассылку</b>
                    </td>
                    <td>
                      <ToggleSwitch
                        checked={userSubscribe}
                        cursor={loadUserSubscribe ? "progress" : "pointer"}
                        onChange={() => {
                          setLoadUserSubscribe(true);
                          updateUserSubscription(!userSubscribe).then((i) => {
                            if (i) {
                              setUserSubscribe(!userSubscribe);
                            }
                            setLoadUserSubscribe(false);
                          });
                        }}
                        name="import"
                        leftText="Отключить"
                        rightText="Подключить"
                        icons={false}
                      />
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </Table>
          </Col>
          {bankInfo ? (
            <Col
              xl={info?.roles.includes("Seller") ? 4 : 5}
              lg={6}
              md={12}
              sm={12}
              xs={12}
            >
              <Table striped hover responsive className={styles.bankInfoTable}>
                <tbody>
                  <tr>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      <b>Банковские данные</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Наименование банка</b>
                    </td>
                    <td>
                      <CustomInput
                        value={bankInfo?.bankName}
                        name={"bankName"}
                        onChange={(e) =>
                          setBankInfo({ ...bankInfo, bankName: e.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>БИК</b>
                    </td>
                    <td>
                      <CustomInput
                        value={bankInfo?.bik}
                        name={"bik"}
                        onChange={(e) =>
                          setBankInfo({ ...bankInfo, bik: e.target.value })
                        }
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Корреспондентский счет</b>
                    </td>
                    <td>
                      <CustomInput
                        value={bankInfo?.correspAccount}
                        name={"correspAccount"}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            correspAccount: e.target.value,
                          })
                        }
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Номер счета в банке</b>
                    </td>
                    <td>
                      <CustomInput
                        value={bankInfo?.personalAccount}
                        name={"personalAccount"}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            personalAccount: e.target.value,
                          })
                        }
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      <div
                        className={
                          changeLoad
                            ? styles.changeButtonLoad
                            : styles.changeButton
                        }
                      >
                        <Button
                          onClick={() => {
                            setChangeLoad(true);
                            changeBankInfo(bankInfo).then(() => {
                              setChangeLoad(false);
                            });
                          }}
                        >
                          Изменить данные
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          ) : (
            ""
          )}
          {info?.roles.includes("WarehouseOwner") ? <Logistics /> : ""}
          {info?.roles.includes("SuperAdmin") ? <AgreementUsage /> : ""}
          <UploadDocuments />
        </Row>
      </div>
      {info?.roles.includes("SuperAdmin") ? <CategoriesTree /> : ""}
    </div>
  );
});
export default ProfileSettings;
