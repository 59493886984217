import React, { useContext, useEffect, useState } from "react";
import styles from "./ProfileSettings.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import BeatLoader from "react-spinners/BeatLoader";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import { CategoriesTreeI } from "stores/WarehouseStore";
import CustomInput from "components/CustomInput";
import Button from "components/Button/Button";
import { TiFlowChildren } from "react-icons/ti";
import ChooseFilters from "./ChooseFilters";
interface CustomToggleProps {
  eventKey: string;
}

const CustomToggle: React.FC<CustomToggleProps> = ({ eventKey }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {});

  return (
    <TiFlowChildren onClick={decoratedOnClick} size={38} cursor={"pointer"} />
  );
};

interface TreeProps {
  children: CategoriesTreeI[];
  changePrice: (price: string, groupID: string) => void;
}
let treeLevel = 0;
const TreeAccordion: React.FC<TreeProps> = ({ children, changePrice }) => {
  treeLevel += 1;

  return (
    <Accordion>
      {children.map((category) => (
        <Card key={category.GroupID}>
          <Card.Header className={styles.CardHeader}>
            <div>
              {category.GroupTitle}
              <span style={{ color: "red" }}>
                {treeLevel === 1 ? " *" : ""}
              </span>
            </div>
            <div className={styles.CardHeaderPrice}>
              <CustomInput
                name={"price"}
                value={category.PackingPrice === 0 ? "" : category.PackingPrice}
                onChange={(e) => {
                  changePrice(e.target.value, category.GroupID);
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              {category.children.length ? (
                <CustomToggle eventKey={category.GroupID} />
              ) : (
                ""
              )}
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey={category.GroupID}>
            <Card.Body>
              {category.children.length ? (
                <TreeAccordion
                  children={category.children}
                  changePrice={changePrice}
                />
              ) : (
                ""
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  );
};

const CategoriesTree: React.FC = observer(() => {
  treeLevel = 0;

  const {
    warehouseStore: {
      getCategoriesTree,
      changeCategoriesTree,
      categoriesTree,
      setCategoriesTree,
    },
  } = useContext(RootStoreContext);

  const setCategoriesTreeData = (
    price: string,
    groupID: string,
    children: CategoriesTreeI[]
  ) => {
    return children?.map((child): any => {
      if (child.GroupID === groupID) {
        return { ...child, PackingPrice: Number(price) };
      } else if (child.children.length) {
        return {
          ...child,
          children: setCategoriesTreeData(price, groupID, child.children),
        };
      } else {
        return child;
      }
    });
  };

  const changePrice = (price: string, groupID: string) => {
    if (categoriesTree) {
      setCategoriesTree(setCategoriesTreeData(price, groupID, categoriesTree));
    }
  };

  const [loadingTree, setLoadingTree] = useState<boolean>(false);

  useEffect(() => {
    setLoadingTree(true);
    getCategoriesTree().then(() => setLoadingTree(false));
  }, [getCategoriesTree]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.treeWrapperHeader}>
        <p className={styles.title}>Стоимость упаковки</p>
        <Button
          disabled={loadingTree}
          onClick={() => {
            if (categoriesTree) {
              changeCategoriesTree().then((i) => {
                if (i) {
                  setLoadingTree(true);
                  getCategoriesTree().then(() => setLoadingTree(false));
                }
              });
            }
          }}
        >
          Сохранить изменения
        </Button>
      </div>
      {loadingTree ? (
        <div style={{ width: "fit-content", margin: "auto" }}>
          <BeatLoader color={"#f49100"} loading={true} size={38} />
        </div>
      ) : categoriesTree ? (
        <TreeAccordion children={categoriesTree} changePrice={changePrice} />
      ) : (
        ""
      )}
      <ChooseFilters />
    </div>
  );
});

export default CategoriesTree;
