import { makeAutoObservable } from "mobx";
import extendDefaultHeaders from "utils/extendDefaultHeaders";
import handleApiPath from "utils/handleApiPath";
import { RootStore } from "./RootStore";
import { toast } from "react-hot-toast";

interface IOzonStocks {
  item1: number;
  item2: string;
}

class OzonStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  isLoading = false;

  ozonStocks: IOzonStocks[] | null = null;

  mpOzonStocks: IOzonStocks[] | null = null;

  setIsLoading = (value: boolean): void => {
    this.isLoading = value;
  };

  setOzonStocks = (data: any): void => {
    this.ozonStocks = data;
  };

  setMPOzonStocks = (data: any): void => {
    this.mpOzonStocks = data;
  };

  getOzonShipmentAct = async (userId: string): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_OZON");
      const response = await fetch(
        `${endpoint}/GetShipmentAct/${userId}`,
        requestOptions
      );
      const data = await response.blob();
      if (response.ok) {
        this.setIsLoading(false);
        return data;
      } else {
        this.setIsLoading(false);
        return null;
      }
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  GetOzonProductOffer = async (
    UserID: string,
    ProductID: string
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        UserID: UserID,
        ProductID: ProductID,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_OZON");
      const response = await fetch(
        `${endpoint}/GetOzonProductOffer?UserID=${UserID}&ProductID=${ProductID}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        return data;
      } else {
        return null;
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  setOzonProductNewPrice = async (
    UserID: string,
    ProductID: string,
    NewPriceOffer: string
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_OZON");
      const response = await fetch(
        `${endpoint}/SetOzonProductNewPrice?UserID=${UserID}&ProductID=${ProductID}&NewPriceOffer=${NewPriceOffer}`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Цена товара успешно установлена");
      } else {
        toast.error("Произошла ошибка");
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      toast.error("Произошла ошибка");
    }
  };

  ozonCancelOrder = async (OrderId: number): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_OZON");
      const response = await fetch(
        `${endpoint}/CancelOrder?OrderID=${OrderId}`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Заказ отменён");
      } else {
        toast.error("Произошла ошибка");
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      toast.error("Произошла ошибка");
    }
  };

  ApproveOzonShipment = async (
    UserID: string,
    StockID: number
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_OZON");
      const response = await fetch(
        `${endpoint}/ApproveShipment/${UserID}`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Акт отгрузки подтвержден");
      } else {
        toast.error("Повторите попытку позже");
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      toast.error("Повторите попытку позже");
    }
  };

  getOzonStocks = async (id: string): Promise<IOzonStocks[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_OZON");
      const response = await fetch(
        `${endpoint}/GetOzonUserWarehouses?UserID=${id}`,
        requestOptions
      );
      const data = await response.json();
      if (response.ok) {
        this.setOzonStocks(data);
        return data;
      } else {
        return null;
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  getMPOzonStocks = async (): Promise<IOzonStocks[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_OZON");
      const response = await fetch(`${endpoint}/GetOzonStocks`, requestOptions);
      const data = await response.json();
      if (response.ok) {
        this.setMPOzonStocks(data);
        return data;
      } else {
        return null;
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  getOzonReturnAct = async (userID: string, type: string): Promise<string> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_OZON");
      const response = await fetch(
        `${endpoint}/GetReturnsAct?userID=${userID}`,
        requestOptions
      );
      const blob = await response.blob();

      if (response.ok && blob) {
        if (type === "download") {
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = "ozonReturnAct.pdf";
          a.click();
        }
        this.setIsLoading(false);
        return window.URL.createObjectURL(blob);
      } else {
        this.setIsLoading(false);
        return "";
      }
    } catch (error) {
      this.setIsLoading(false);
      return "";
    }
  };
}

export default OzonStore;
