import React, { useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Drawer from "./components/Drawer";
import MobileHeader from "./components/MobileHeader";
import Avatar from "./components/Avatar";
import Home from "./Home";
import Settings from "./Settings";
import ProfileSettings from "./ProfileSettings";
import Products from "./Products";
import ProductItem from "./ProductItem";
import styles from "./Dashboard.module.scss";
import Cart from "./Cart";
import Marketplaces from "./Marketplaces";
import Favorites from "./Favorites";
import PriceHistory from "./PriceHistory";
import Collations from "./Collations";
import Invoices from "./Invoices";
import Users from "./Users";
import NotFoundPage from "./NotFoundPage";
import Returns from "./Returns";
import Videos from "./Videos";
import Faq from "./Faq";
import NewProducts from "./NewProducts";
import Finance from "./Finance";
import Collections from "./Collections";
import { RootStoreContext } from "../../stores/RootStore";
import Agreement from "./Agreement";
import Email from "./Email";
import UpdateInfo from "./UpdateInfo";
import Analytics from "./Analytics";
import TermsOfCondition from "./components/TermsOfCondition";
import Obligation from "./Obligations";

interface Props {
  user: boolean;
}

const Dashboard: React.FC<Props> = observer(({ user }) => {
  const {
    userStore: { info, userInfo, getUserInfo },
    marketPlacesStore: { getIsMPBanned },
  } = useContext(RootStoreContext);

  useEffect(() => {
    if (info) {
      getUserInfo(info.id).then((userInfo) => {
        if (userInfo) {
          getIsMPBanned(userInfo.mpWarehouseID, 1);
          getIsMPBanned(userInfo.mpWarehouseID, 2);
          getIsMPBanned(userInfo.mpWarehouseID, 3);
        }
      });
    }
  }, [getUserInfo, info, getIsMPBanned]);
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  return (
    <div className={styles.root}>
      <div className={styles.drawer}>
        <Drawer />
      </div>
      <div className={styles.content}>
        <div className={styles.mobileHeader}>
          <MobileHeader avatar={<Avatar />} />
        </div>
        {userInfo ? (
          userInfo.agreementDate && userInfo.roomsellerConditionsConsent ? (
            <Routes>
              <Route path="home" element={<Home />} />
              <Route path="settings" element={<Settings />} />
              <Route path="warehouse" element={<Products />} />
              <Route path="warehouse/product/*" element={<ProductItem />} />
              <Route path="marketplaces" element={<Marketplaces />} />
              <Route path="cart" element={<Cart />} />
              <Route path="profile" element={<ProfileSettings />} />
              <Route path="favorites" element={<Favorites />} />
              <Route path="priceHistory" element={<PriceHistory />} />
              <Route path="collations" element={<Collations />} />
              <Route path="invoices" element={<Invoices />} />
              <Route path="returns" element={<Returns />} />
              <Route path="users" element={<Users />} />
              <Route path="videos" element={<Videos />} />
              <Route path="*" element={<NotFoundPage />} />
              <Route path="faq" element={<Faq />} />
              <Route path="new" element={<NewProducts />} />
              <Route path="finance" element={<Finance />} />
              <Route path="collections" element={<Collections />} />
              <Route path="subscribtions" element={<Email />} />
              <Route path="updateInfo" element={<UpdateInfo />} />
              <Route path="analytics" element={<Analytics />} />
              <Route path="termsOfUsage" element={<TermsOfCondition />} />
              <Route path="obligations" element={<Obligation />} />
            </Routes>
          ) : (
            <Agreement />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
});

export default Dashboard;
