import Product from "../components/Product";
import CustomSelect, { SelectValue } from "components/CustomSelect";
import Checkbox from "components/Checkbox";
import classNames from "classnames";
import { components } from "react-select";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import styles from "./Products.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import Icon from "../../../components/Icon";
import Button from "components/Button";
import BeatLoader from "react-spinners/BeatLoader";
import CustomInput from "components/CustomInput";

import { useSearchParams } from "react-router-dom";
import Modal from "../../../components/Modal";
import CollectionCreate from "./CollectionCreate";
import { toast } from "react-hot-toast";
import { HiViewGridAdd } from "react-icons/hi";

import OzonLogo from "assets/icons/ozon.png";
import WildberriesLogo from "assets/icons/wildberries.png";
import YandexLogo from "assets/icons/yandex.png";
import LogisticPopup from "./LogisticPopup";

const Products = observer(() => {
  const {
    warehouseStore: {
      products,
      getProducts,
      groups,
      orders,
      getOrders,
      minPrice,
      maxPrice,
    },
    marketPlacesStore: {
      marketplaces,
      getMarketPlaces,
      getIsMPBanned,
      userWarehouses,
    },
    collectionsStore: { addProductToCollection },
  } = useContext(RootStoreContext);

  const [searchValue, setSearchValue] = useState<string>("");
  const viewType = 1;
  const [inputMinPrice, setInputMinPrice] = useState<number | null>(null);
  const [inputMaxPrice, setInputMaxPrice] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const newParams = new URLSearchParams(searchParams);

  const properties = searchParams.getAll("propertyID");
  const propertiesPOST = JSON.stringify(properties);
  const age = searchParams.get("age");
  const mpWarehouseID = searchParams.get("mpWarehouseID");
  const notAddedToMarketPlace = searchParams.get("notAddedToMarketPlace");
  const group = searchParams.get("group");
  const order = searchParams.get("order");
  const pageSize = searchParams.get("pageSize");
  const marketplace = searchParams.get("marketplace");
  const searchText = searchParams.get("search");
  const pageNumber = searchParams.get("pageNumber");
  const MIN_PRICE = searchParams.get("min_price");
  const MAX_PRICE = searchParams.get("max_price");

  const [checkMP, setCheckMP] = useState(marketplace ? true : false);
  const [checkNMP, setCheckNMP] = useState(
    notAddedToMarketPlace ? true : false
  );

  const [addProductsLoading, setAddProductsLoading] = useState<boolean>(false);
  const [collectionPopupOpen, setCollectionPopupOpen] =
    useState<boolean>(false);
  const [collectionMode, setCollectionMode] = useState<boolean>(false);
  const [collectionID, setCollectionID] = useState<string>("");
  const [collectionAddProducts, setCollectionAddProducts] = useState<
    { product: string; quantity: number }[]
  >([]);

  const [logisticPopupOpen, setLogisticPopupOpen] = useState<boolean>(false);
  const [logisticMP, setLogisticMP] = useState<string>("");

  const [ozonIsBanned, setOzonIsBanned] = useState<boolean>(false);
  const [yandexIsBanned, setYandexIsBanned] = useState<boolean>(false);
  const [wildberriesIsBanned, setWildberriesIsBanned] =
    useState<boolean>(false);

  const propertyValueIDsFilter = products?.propertyValues
    ? Object.keys(products?.propertyValues).map((propName) => {
        if (
          products?.propertyValues[propName].filter((i) =>
            properties.includes(i.id)
          )[0]
        ) {
          return {
            title: propName,
            label: propName,
            value: products?.propertyValues[propName].filter((i) =>
              properties.includes(i.id)
            )[0].id,
          };
        }
      })
    : [];
  const filters = [
    {
      title: "Категория",
      label: "group",
      value: groups.filter((i) => i.value === group)[0]
        ? groups.filter((i) => i.value === group)[0].label
        : null,
    },
    {
      title: "Сортировка",
      label: "order",
      value: orders.filter((i) => i.value == order)[0]
        ? orders.filter((i) => i.value == order)[0].label
        : null,
    },
    {
      title: "Не добавленные на МП",
      label: "notAddedToMarketPlace",
      value: checkNMP,
    },
    {
      title: "Маркетплейс",
      label: "marketplace",
      value: marketplaces.filter((i) => i.id === Number(marketplace))[0]
        ? marketplaces.filter((i) => i.id === Number(marketplace))[0].name
        : checkMP
        ? "Добавленные в МП"
        : null,
    },
    { title: "Поиск", label: "search", value: searchText },
    {
      title: "Цена",
      label: "price",
      value:
        (MIN_PRICE || MAX_PRICE) &&
        minPrice !== null &&
        maxPrice &&
        (Number(MIN_PRICE) !== minPrice || Number(MAX_PRICE) !== maxPrice)
          ? "от " +
            (MIN_PRICE ? MIN_PRICE : minPrice) +
            " до " +
            (MAX_PRICE ? MAX_PRICE : maxPrice)
          : null,
    },
  ];
  useEffect(() => {
    getMarketPlaces();
    getOrders();
    if (mpWarehouseID) {
      getIsMPBanned(Number(mpWarehouseID), 1).then((i) => {
        setOzonIsBanned(i);
      });
      getIsMPBanned(Number(mpWarehouseID), 2).then((i) => {
        setYandexIsBanned(i);
      });
      getIsMPBanned(Number(mpWarehouseID), 3).then((i) => {
        setWildberriesIsBanned(i);
      });
    }
  }, [getMarketPlaces, getOrders, getIsMPBanned, mpWarehouseID]);

  useEffect(() => {
    setInputMinPrice(Number(MIN_PRICE));
    setInputMaxPrice(Number(MAX_PRICE));

    if (searchText) {
      setSearchValue(searchText);
    }

    if (minPrice !== null && !MIN_PRICE) {
      setInputMinPrice(minPrice);
    }
    if (maxPrice && !MAX_PRICE) {
      setInputMaxPrice(maxPrice);
    }
  }, [
    maxPrice,
    minPrice,
    MIN_PRICE,
    MAX_PRICE,
    searchText,
    setInputMinPrice,
    setInputMaxPrice,
  ]);

  useEffect(() => {
    setLoading(true);
    getProducts({
      ...(group && {
        groupID: group,
      }),
      ...(pageSize && { pageSize: Number(pageSize) }),
      ...(checkNMP && {
        notAddedToMarketPlace: checkNMP,
      }),
      ...(marketplace && { marketPlaceID: Number(marketplace) }),
      ...(MIN_PRICE && { priceMin: Number(MIN_PRICE) }),
      ...(MAX_PRICE && { priceMax: Number(MAX_PRICE) }),
      ...(searchText && { searchText: searchText }),
      ...(pageNumber && { pageNumber: Number(pageNumber) }),
      ...(order && { sortingOrder: Number(order) }),
      ...(mpWarehouseID && { mpWarehouseID: Number(mpWarehouseID) }),
      ...(JSON.parse(propertiesPOST).length && {
        propertyValueIDs: JSON.parse(propertiesPOST),
      }),
    }).then(() => {
      setLoading(false);
    });
  }, [
    MIN_PRICE,
    MAX_PRICE,
    searchText,
    group,
    mpWarehouseID,
    checkNMP,
    pageSize,
    marketplace,
    order,
    pageNumber,
    propertiesPOST,
    getProducts,
  ]);

  const handlePages = (updatePage: number) => {
    newParams.set("pageNumber", "1");
    newParams.set("pageNumber", `${updatePage}`);
    setSearchParams(newParams);
  };

  const handleFilters = (e: SelectValue, name: string, oldValue?: string) => {
    if (oldValue !== undefined) {
      if (oldValue !== "") {
        (newParams as any).delete(name, oldValue);
        newParams.append(name, e.value);
      } else {
        newParams.append(name, e.value);
      }
    } else {
      newParams.set(name, e.value);
    }

    newParams.set("pageNumber", "1");
    setSearchParams(newParams);
  };

  const handleSearchFilter = () => {
    newParams.set("pageNumber", "1");
    newParams.set("search", searchValue);
    setSearchParams(newParams);
  };

  const handlePrices = (value: number, name: string) => {
    newParams.set("pageNumber", "1");
    newParams.set(name, value.toString());
    setSearchParams(newParams);
  };
  const MenuList: React.FC<any> = (props) => {
    const { children, ...otherProps } = props;
    return (
      <components.MenuList {...otherProps}>{children}</components.MenuList>
    );
  };

  const addProductsToCollection = () => {
    setAddProductsLoading(true);
    let count = 0;

    collectionAddProducts.forEach((i) => {
      addProductToCollection(
        collectionID.replaceAll(`"`, ""),
        i.product,
        i.quantity
      );
      count++;
      if (count === collectionAddProducts.length) {
        toast.success("Товары успешно добавлены в набор", { duration: 5000 });
        setCollectionAddProducts([]);
        setCollectionID("");
        setCollectionMode(false);
        setAddProductsLoading(false);
      }
    });
  };
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.warehouseName}>
          {
            userWarehouses?.filter((i) => i.item1 === Number(mpWarehouseID))[0]
              .item2
          }
        </div>
        <div className={styles.filters}>
          {mpWarehouseID ? (
            <>
              <CustomSelect
                components={{ MenuList }}
                className={styles.normal}
                name="groups"
                placeholder="Категория"
                onChange={(e) => {
                  handleFilters(e, "group");
                }}
                isDisabled={loading}
                value={group}
                list={groups as any}
              />
              {products?.propertyValues &&
                Object.keys(products?.propertyValues).map((propName) => (
                  <CustomSelect
                    key={propName}
                    components={{ MenuList }}
                    className={styles.normal}
                    name={propName}
                    placeholder={propName}
                    onChange={(e) => {
                      handleFilters(
                        e,
                        "propertyID",
                        products.propertyValues[propName].filter((item) =>
                          properties.includes(item.id)
                        )[0]
                          ? products.propertyValues[propName].filter((item) =>
                              properties.includes(item.id)
                            )[0].id
                          : ""
                      );
                    }}
                    value={
                      products.propertyValues[propName].filter((item) =>
                        properties.includes(item.id)
                      )[0]
                        ? products.propertyValues[propName].filter((item) =>
                            properties.includes(item.id)
                          )[0].id
                        : ""
                    }
                    isDisabled={loading}
                    list={
                      products.propertyValues[propName].map((item) => {
                        return { value: item.id, label: item.title };
                      }) as any
                    }
                  />
                ))}
            </>
          ) : (
            ""
          )}
          <CustomSelect
            className={styles.normal}
            name="order"
            placeholder="Сортировка"
            onChange={(e) => {
              handleFilters(e, "order");
            }}
            value={order}
            isDisabled={loading}
            list={orders as any}
          />
          <div className={styles.marketPlacesLogo}>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {ozonIsBanned ? "Склад Ozon отключен" : "Логистика Ozon"}
                </Tooltip>
              }
            >
              <div className={styles.IsMpBannedContent}>
                {ozonIsBanned ? (
                  <div className={styles.IsMpBannedDisabled}></div>
                ) : (
                  ""
                )}
                <img
                  src={OzonLogo}
                  alt="ozon logo"
                  onClick={() => {
                    if (!ozonIsBanned) {
                      setLogisticPopupOpen(true);
                      setLogisticMP("Ozon");
                    }
                  }}
                />
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {wildberriesIsBanned
                    ? "Склад Wildberries отключен"
                    : "Логистика Wildberries"}
                </Tooltip>
              }
            >
              <div className={styles.IsMpBannedContent}>
                {wildberriesIsBanned ? (
                  <div className={styles.IsMpBannedDisabled}></div>
                ) : (
                  ""
                )}
                <img
                  src={WildberriesLogo}
                  alt="wildberries logo"
                  className={styles.wildberriesLogo}
                  onClick={() => {
                    if (!wildberriesIsBanned) {
                      setLogisticPopupOpen(true);
                      setLogisticMP("Wildberries");
                    }
                  }}
                />
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {yandexIsBanned
                    ? "Склад Яндекс Маркет отключен"
                    : "Логистика Яндекс Маркет"}
                </Tooltip>
              }
            >
              <div className={styles.IsMpBannedContent}>
                {yandexIsBanned ? (
                  <div className={styles.IsMpBannedDisabled}></div>
                ) : (
                  ""
                )}
                <img
                  src={YandexLogo}
                  alt="yandex market logo"
                  onClick={() => {
                    if (!yandexIsBanned) {
                      setLogisticPopupOpen(true);
                      setLogisticMP("Яндекс Маркет");
                    }
                  }}
                />
              </div>
            </OverlayTrigger>
          </div>
        </div>
        {mpWarehouseID ? (
          <>
            <Row>
              <Col
                xl={5}
                lg={7}
                md={8}
                sm={6}
                xs={12}
                className={styles.marketPlaceDiv}
              >
                <Checkbox
                  checked={checkMP}
                  onChange={() => {
                    setCheckMP(!checkMP);
                    newParams.delete("marketplace");
                    setSearchParams(newParams);
                    if (!checkMP) {
                      newParams.set("marketplace", "0");
                      newParams.set("pageNumber", "1");
                      setSearchParams(newParams);
                    }
                  }}
                  disabled={loading || checkNMP}
                  className={styles.normalCheckbox}
                  label={"Добавленные в МП"}
                />
                <CustomSelect
                  isDisabled={!checkMP || loading || checkNMP}
                  className={styles.normal}
                  name="marketplace"
                  placeholder="Маркетплейс"
                  onChange={(e) => {
                    handleFilters(e, "marketplace");
                  }}
                  value={Number(marketplace)}
                  list={[
                    {
                      value: "0",
                      label: "Все",
                    },
                    ...(marketplaces.map((item) => ({
                      value: item.id,
                      label: item.name,
                    })) as any),
                  ]}
                />
                <Checkbox
                  checked={checkNMP}
                  onChange={() => {
                    setCheckNMP(!checkNMP);
                    newParams.delete("notAddedToMarketPlace");
                    setSearchParams(newParams);
                    if (!checkNMP) {
                      newParams.set("notAddedToMarketPlace", "true");
                      newParams.set("pageNumber", "1");
                      setSearchParams(newParams);
                    }
                  }}
                  disabled={loading || checkMP}
                  className={styles.normalCheckbox}
                  label={"Не добавленные в МП"}
                />
              </Col>
              <Col
                xl={3}
                lg={5}
                md={4}
                sm={6}
                xs={12}
                className={styles.priceDiv}
              >
                <p className={styles.priceText}>Цена</p>
                <CustomInput
                  placeholder="от"
                  name="minPrice"
                  value={inputMinPrice !== null ? inputMinPrice : ""}
                  onChange={(e) => setInputMinPrice(Number(e.target.value))}
                  onBlur={() =>
                    inputMinPrice !== Number(MIN_PRICE) &&
                    inputMinPrice !== null &&
                    inputMaxPrice !== null &&
                    inputMinPrice < inputMaxPrice
                      ? handlePrices(inputMinPrice, "min_price")
                      : setInputMinPrice(Number(MIN_PRICE))
                  }
                  onKeyUp={(e) =>
                    e.keyCode === 13
                      ? inputMinPrice !== Number(MIN_PRICE) &&
                        inputMinPrice !== null &&
                        inputMaxPrice !== null &&
                        inputMinPrice < inputMaxPrice
                        ? handlePrices(inputMinPrice, "min_price")
                        : setInputMinPrice(Number(MIN_PRICE))
                      : {}
                  }
                  className={styles.priceInput}
                  disabled={loading}
                  type="number"
                />
                <CustomInput
                  placeholder="до"
                  name="maxPrice"
                  value={inputMaxPrice !== null ? inputMaxPrice : ""}
                  onChange={(e) => setInputMaxPrice(Number(e.target.value))}
                  onBlur={() =>
                    inputMaxPrice !== Number(MAX_PRICE) &&
                    inputMinPrice !== null &&
                    inputMaxPrice !== null &&
                    inputMaxPrice > inputMinPrice
                      ? handlePrices(inputMaxPrice, "max_price")
                      : setInputMaxPrice(Number(MAX_PRICE))
                  }
                  onKeyUp={(e) =>
                    e.keyCode === 13
                      ? inputMaxPrice !== Number(maxPrice) &&
                        inputMaxPrice !== null &&
                        inputMinPrice !== null &&
                        inputMaxPrice > inputMinPrice
                        ? handlePrices(inputMaxPrice, "max_price")
                        : setInputMaxPrice(Number(MAX_PRICE))
                      : {}
                  }
                  className={styles.priceInput}
                  disabled={loading}
                  type="number"
                />
              </Col>
              <Col xl={4} lg={6} md={9} sm={12} xs={12}>
                <div className={styles.search}>
                  <CustomInput
                    type="search"
                    value={searchValue}
                    placeholder="Поиск..."
                    name={`search`}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      if (!e.target.value) {
                        newParams.delete("search");
                        setSearchParams(newParams);
                      }
                    }}
                    onKeyPress={(event) =>
                      event.key === "Enter" && handleSearchFilter()
                    }
                    className={styles.searchInput}
                    disabled={loading}
                  />
                  <Button
                    text="Поиск"
                    color="primary"
                    className={styles.searchButton}
                    onClick={() => handleSearchFilter()}
                  />
                </div>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}

        {products && products.products.length ? (
          <div className={styles.tags}>
            {[...propertyValueIDsFilter, ...filters].map(
              (item, index) =>
                item &&
                item.value && (
                  <div className={styles.tag} key={index}>
                    <div className={styles.label}>{item.title}</div>
                    <Icon
                      onClick={() => {
                        (newParams as any).delete(
                          "propertyID",
                          item.value?.toString()
                        );
                        setSearchParams(newParams);
                      }}
                      variant="cross"
                      size="small"
                    />
                  </div>
                )
            )}
            {[...propertyValueIDsFilter, ...filters].filter((i) => i && i.value)
              .length ? (
              <a
                className={styles.reset}
                onClick={() => {
                  setSearchParams({
                    pageNumber: "1",
                    pageSize: "20",
                    mpWarehouseID: mpWarehouseID ? mpWarehouseID : "",
                  });
                  setSearchValue("");
                  setCheckMP(false);
                }}
              >
                Сбросить все фильтры
              </a>
            ) : (
              ""
            )}
            <div style={{ width: "100%" }}>
              {collectionMode ? (
                <Button
                  text={`Добавить товары в набор(${collectionAddProducts.length})`}
                  color="primary"
                  onClick={() => addProductsToCollection()}
                  disabled={collectionAddProducts.length === 0}
                  className={
                    addProductsLoading
                      ? styles.collectionButtonLoad
                      : styles.collectionButton
                  }
                />
              ) : (
                <Button
                  text="Создать набор"
                  children={<HiViewGridAdd size={25} />}
                  color="primary"
                  onClick={() => setCollectionPopupOpen(true)}
                  className={styles.collectionButton}
                />
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <Row>
          {loading ? (
            <div style={{ width: "fit-content", margin: "auto" }}>
              <BeatLoader color={"#f49100"} loading={loading} size={50} />
            </div>
          ) : products && products.products.length && mpWarehouseID ? (
            products.products.map((item, index) => (
              <Col xl={3} lg={4} md={6} sm={6} xs={12} key={index + item.id}>
                <Product
                  data={item}
                  list={viewType === 1}
                  collectionMode={collectionMode}
                  collectionAddProducts={collectionAddProducts}
                  setCollectionAddProducts={setCollectionAddProducts}
                />
              </Col>
            ))
          ) : (
            <div className={styles.notFoundResult}>
              <div className={styles.notFoundResultWrap}>
                <p className={styles.p1}>Не нашлось подходящих товаров</p>
                <p className={styles.p2}>
                  Попробуйте поменять параметры или{" "}
                  <button
                    onClick={() => {
                      setSearchParams({ pageNumber: "1", pageSize: "20" });
                      setSearchValue("");
                      setCheckMP(false);
                    }}
                  >
                    сбросить фильтры
                  </button>
                </p>
              </div>
            </div>
          )}
        </Row>
        {!loading
          ? products &&
            products.pageCount > 1 && (
              <div className={classNames(styles.pagination)}>
                <CustomSelect
                  className={styles.small}
                  name="pageSize"
                  placeholder="qty"
                  onChange={(e) => {
                    handleFilters(e, "pageSize");
                  }}
                  value={pageSize ? pageSize : { value: "20", label: "20" }}
                  list={[
                    { value: "20", label: "20" },
                    { value: "40", label: "40" },
                    { value: "60", label: "60" },
                  ]}
                />
                <Pagination
                  page={Number(pageNumber)}
                  totalPages={products.pageCount}
                  handlePagination={handlePages}
                />
              </div>
            )
          : ""}
        {collectionPopupOpen && mpWarehouseID && (
          <Modal
            className={styles.cartModal}
            children={
              <CollectionCreate
                setCollectionMode={setCollectionMode}
                setCollectionID={setCollectionID}
                setCollectionPopupOpen={setCollectionPopupOpen}
                warehouseID={mpWarehouseID}
              />
            }
            isOpen={collectionPopupOpen}
            onClose={() => {
              setCollectionPopupOpen(false);
            }}
          />
        )}
        {logisticPopupOpen && mpWarehouseID && (
          <Modal
            className={styles.cartModal}
            children={
              <LogisticPopup mpName={logisticMP} warehouseID={mpWarehouseID} />
            }
            isOpen={logisticPopupOpen}
            onClose={() => {
              setLogisticPopupOpen(false);
            }}
          />
        )}
      </div>
    </div>
  );
});
export default Products;
