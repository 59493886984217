import { Row, Table } from "react-bootstrap";
import React, { Fragment, useContext, useState } from "react";
import { RootStoreContext } from "../../../stores/RootStore";
import styles from "./Invoices.module.scss";
import { observer } from "mobx-react-lite";
import Button from "components/Button";

interface Props {
  payItems: { price: number; invoiceNumber: number }[];
}

const PayPopup: React.FC<Props> = observer(({ payItems }) => {
  const {
    orderStore: { payInit },
  } = useContext(RootStoreContext);

  const [loading, setLoading] = useState<boolean>(false);

  const payClick = (orders: string[]) => {
    setLoading(true);
    payInit(orders).then((i) => {
      if (i) {
        window.open(i);
      }
      setLoading(false);
    });
  };
  return (
    <Row className={styles.cartPayModalRoot}>
      <Table striped hover>
        <thead>
          <tr>
            <th>Заказы</th>
            <th>Общая сумма</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {payItems.map((i, index) => (
                <Fragment key={i.invoiceNumber.toString()}>
                  {i.invoiceNumber}
                  {index < payItems.length - 1 ? ", " : ""}
                </Fragment>
              ))}
            </td>
            <td>
              {` 
        ${payItems.reduce(
          (total, currentValue) => (total = total + currentValue.price),
          0
        )} ₽ `}
            </td>
          </tr>
        </tbody>
      </Table>
      <Button
        text="Оплатить"
        className={loading ? styles.payButtonLoad : styles.payButton}
        onClick={() =>
          payClick(payItems.map((i) => i.invoiceNumber.toString()))
        }
      />
    </Row>
  );
});

export default PayPopup;
